import React from "react";
import { ReactComponent as FACEBOOK } from "../../assets/facebook1.svg";
import { ReactComponent as TWITTER } from "../../assets/twitter1.svg";
import { ReactComponent as INSTAGRAM } from "../../assets/instagram1.svg";
import { ReactComponent as RACKET } from "../../assets/racket.svg";
import { ReactComponent as SMSM } from "../../assets/smsMobileIcon.svg";

import { Link } from "react-scroll";

function Footer() {
  const token = localStorage.getItem("refreshToken");
  return (
    <>
      <div className="footerStyle">
        <div className="contentStyle">
          <div className="iconStyles">
            <a
              href="https://www.instagram.com/kbtc.com.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <INSTAGRAM />
            </a>
            <a
              href="https://www.facebook.com/kbtc.com.au"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FACEBOOK />
            </a>
            <a
              href="https://x.com/kbtc_com_au"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TWITTER />
            </a>
            <div>
              <RACKET className="kbtcFooterLogo" />
            </div>
          </div>
          <div style={{ padding: 10 }}>
            <span className="footerContentColor">Operating Hours</span>
            <br />
            Mon – Fri : 06:00am to 10.00pm
            <br />
            Sat – Sun : 06:00am to 10.00pm
          </div>

          <div style={{ padding: 10 }}>
            <span className="footerContentColor">Useful Links</span>
            <br />
            <a href={token ? "/profile" : "/login"} className="atag">
              Become a Member
            </a>
            <br />
            <a href="/login" className="atag">
              Book Court
            </a>
            <br />
            <a href="/terms&conditions" className="atag">
              Terms & Condition
            </a>
            <br />
            <a href="/privacy-policy" className="atag">
              Privacy Policy
            </a>
            <br />
            <a href="/terms&conditions" className="atag">
              Refund Policy
            </a>
          </div>

          <div style={{ padding: 10 }}>
            <span className="footerContentColor">Contact Us</span>
            <br />
            <div className="smsTextMobile">
              <a
                href="mailto:support@kbtc.com.au"
                className="atag"
                style={{ color: "#fad927", fontSize: "16px" }}
              >
                <SMSM className="smsFooterIcon" />
                support@kbtc.com.au
              </a>
            </div>
            <div className="smsText">
              <a href="mailto:support@kbtc.com.au" className="atag">
                support@kbtc.com.au
              </a>
            </div>
            <a
              href="https://www.google.com/maps/place/50+E+Cherry+Ln,+Laverton+North+VIC+3026,+Australia/@-37.8349588,144.7982513,17z/data=!3m1!4b1!4m6!3m5!1s0x6ad661e96fc61b23:0xf16e05cd02641c2d!8m2!3d-37.8349631!4d144.8008262!16s%2Fg%2F11sw3l033t?entry=ttu"
              className="atag"
              target="_blank"
              rel="noopener noreferrer"
            >
              50E Cherry Lane, Laverton North,
              <br />
              Victoria, Australia 3026
            </a>
            <br />
            <a href="tel:+61394488955" className="atag">
              03 9448 8955
            </a>
          </div>

          <div className="footerSocial" style={{ padding: 10 }}>
            <Link to="home" title="Go to home" style={{ cursor: "pointer" }}>
              <RACKET className="kbtcFooterLogo" />
            </Link>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginLeft: 20,
              }}
            >
              <a
                href="https://www.instagram.com/kbtc.com.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <INSTAGRAM className="footerSocialIcon" />
              </a>
              <a
                href="https://www.facebook.com/kbtc.com.au"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FACEBOOK className="footerSocialIcon" />
              </a>
              <a
                href="https://x.com/kbtc_com_au"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TWITTER className="footerSocialIcon" />
              </a>
            </div>
          </div>
        </div>
        <div className="setline">
          <svg width="100%" height="1">
            <line
              x1="0"
              y1="0"
              x2="100%"
              y2="0"
              style={{ stroke: "white", strokeWidth: 1 }}
            />
          </svg>
        </div>
        <footer className="setfooter">
          © All Rights Reserved. 2024, Krishna’s Badminton Center.
        </footer>
      </div>
    </>
  );
}

export default Footer;
