import { Button } from "react-scroll";
import { ReactComponent as RACKETHALF } from "../../assets/rackethalf1.svg";
import { ReactComponent as SUTTLE } from "../../assets/bluesuttle.svg";

import { useNavigate } from "react-router-dom";

function Membership() {
  const navigate = useNavigate();

  const becomeMember = () => {
    if (localStorage.getItem("refreshToken")) {
      navigate("/profile");
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <div className="publicheader">
        <p className="publicheaderTitle">Membership Benefits</p>
      </div>
      <div className="publicContent">
        <div className="buttonContent">
          <Button className="memberButton" onClick={becomeMember}>
            Become Member
          </Button>
        </div>
        <p>
          Unlock a world of exclusive benefits by becoming a member of
          <b> Krishna's Badminton Centre (KBTC)!</b> Our membership program is
          designed to enhance your badminton experience and provide you with
          valuable perks both on and off the court.
        </p>
        <br />
        <div className="racketHalfIcon">
          <RACKETHALF style={{ width: 150 }} />
        </div>
        <div className="memberheader">
          <p className="memberTitle">
            <b>As a KBTC member, you'll enjoy the following benefits</b>
          </p>
        </div>
        <div className="memberContent">
          <SUTTLE className="bulletSuttle" />
          <p className="bulletParagraph">
            <b>Discounted Court Hire Fees :</b> Enjoy special rates on court
            bookings, allowing you to play more for less and make the most of
            your time on the court.
          </p>
          <SUTTLE className="bulletSuttle" />
          <p className="bulletParagraph">
            <b>Exclusive deals On KBTC Merchandise:</b> Receive exclusive
            discounts on our range of merchandise, including equipment, apparel,
            and accessories, to gear up for your games in style.
          </p>
          <SUTTLE className="bulletSuttle" />
          <p className="bulletParagraph">
            <b>Regular Court Bookings (Permanent Bookings):</b> Secure your
            preferred playing times with regular court bookings. our staff
            assists you in organizing a regular booking schedule until your
            membership expires, ensuring you have consistent access to the
            courts. Court preferences and booking times will be based on
            availability.
          </p>
          <SUTTLE className="bulletSuttle" />
          <p className="bulletParagraph">
            <b>Email updates And support:</b> Stay informed and connected with
            all things KBTC. As a member, you'll receive regular updates on
            court bookings, events, promotions, and KBTC news via email. Plus,
            enjoy direct support from our team in case of any issues or
            inquiries.
          </p>
        </div>
      </div>
    </>
  );
}

export default Membership;
