import React, { useEffect, useState } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export default function BookingSuccess() {
  const [countDown, setCountDown] = useState(5);

  useEffect(() => {
    const time = setInterval(() => {
      setCountDown((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearInterval(time);
  }, []);

  useEffect(() => {
    if (countDown === 0) {
      window.location.href = "/make-booking";
    }
  }, [countDown]);

  return (
    <>
      <div className="center">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <TaskAltIcon
            className="scaling-icon"
            style={{ fontSize: 50, color: "#131d32" }}
          />
          <h1
            style={{
              color: "#131d32",
            }}
          >
            Booking Successful
          </h1>
          <p>
            You are redirecting to the booking page in {countDown} second...
          </p>{" "}
          <a
            style={{
              color: "#131d32",
              textDecoration: "none",
            }}
            href="make-booking"
          >
            Redirect Now
          </a>
        </div>
      </div>
    </>
  );
}
