import AxiosInstance from "./AxiosInterceptor";

export const getRequest = (apiUrl, params = {}) => {
  return AxiosInstance.get(apiUrl, {
    params: params,
  })
    .then((response) => ({
      payload: response,
    }))
    .catch((error) => {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      // eslint-disable-next-line
      throw { error: errorMessage };
    });
};

export const getRequestWithId = (apiUrl, id, params = {}) => {
  return AxiosInstance.get(`${apiUrl}${id}`, {
    params: params,
  })
    .then((response) => ({
      payload: response,
    }))
    .catch((error) => {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      // eslint-disable-next-line
      throw { error: errorMessage };
    });
};

export const postRequest = (apiUrl, data, headers = {}) => {
  return AxiosInstance.post(apiUrl, data)
    .then((response) => ({ payload: response }))
    .catch((error) => {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      // eslint-disable-next-line
      throw { error: errorMessage };
    });
};

export const deleteRequest = (apiUrl) => {
  return AxiosInstance.delete(apiUrl)
    .then((response) => ({
      payload: response,
    }))
    .catch((error) => {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      // eslint-disable-next-line
      throw { error: errorMessage };
    });
};

export const deleteRequestWithBody = (apiUrl, data) => {
  return AxiosInstance.delete(apiUrl, {
    data,
  })
    .then((response) => ({
      payload: response,
    }))
    .catch((error) => {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      // eslint-disable-next-line
      throw { error: errorMessage };
    });
};

export const deleteRequestId = (apiUrl, id) => {
  return AxiosInstance.delete(`${apiUrl}${id}`)
    .then((response) => ({
      payload: response,
    }))
    .catch((error) => {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      // eslint-disable-next-line
      throw { error: errorMessage };
    });
};

export const putRequestId = (apiUrl, id, data, headers = {}) => {
  return AxiosInstance.put(`${apiUrl}${id}`, data, {
    headers: { ...headers },
  })
    .then((response) => ({ payload: response }))
    .catch((error) => {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      // eslint-disable-next-line
      throw { error: errorMessage };
    });
};

export const putRequest = (apiUrl, data, queryParams = {}, headers = {}) => {
  return AxiosInstance.put(apiUrl, data, {
    params: { ...queryParams },
    headers: { ...headers },
  })
    .then((response) => ({ payload: response }))
    .catch((error) => {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      // eslint-disable-next-line
      throw { error: errorMessage };
    });
};
