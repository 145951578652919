export const ALLFIELD = "Please fill the required fields!";
export const EMAIL = "Please enter your email!";
export const PASSWORD = "Please enter your password!";
export const CONFIRMPASSWORD = "Please enter your confirmpassword!";
export const FIRSTNAME = "Please enter your firstname!";
export const LASTNAME = "Please enter your lastname!";
export const MOBILENUMBER = "Please enter your mobile number!";
export const USERNAME = "Please enter your username!";
export const POSTCODE = "Please enter your postcode!";
export const MATCHPASSWORD = "ConfirmPassword does't match with password!";
export const VALIDATE_PASSWORD = "Password must be 8 characters long!";
export const VALIDATE_FIRSTNAME = "Enter valid firstname!";
export const VALIDATE_LASTNAME = "Enter valid lastname!";
export const VALIDATE_MOBILE = "Enter valid mobile number!";
export const VALIDATE_PHONE = "Enter valid phone number!";
export const VALIDATE_CONTACT = "Enter valid contact number!";
export const VALIDATE_POSTCODE = "Enter valid postal code!";
export const VALIDATE_EMAIL = "Enter valid email!";
export const SIGNUP_SUCCESS =
  "Signup successful, Verification link has been sent to your registered email, please verify your email address.";
export const PASSWORD_UPDATE_SUCCESS =
  "Password changed successfully. You can login now.";
export const PASSWORD_UPDATE_FAILED =
  "New password and Current password can't be same.";
export const GENDER = "Please select gender!";
export const BOKKINGCANCEL = "Booking Cancelled Successfully";
export const BOKKINGCANCELCREDIT =
  "Booking has been cancelled & The credits have been added to your account";
export const MESSAGE = "Please enter your message!";
export const CHECKBOX = "Please accept the terms & conditions!";

//Court Page
export const REQUIRED_COURT = "Please enter your courtName!";
export const COURT_CREATE = "Court Added Successfully.";
export const COURT_UPDATE = "Court Updated Successfully.";
export const COURT_DELETE = "Court Deleted Successfully.";

//PromoCode page
export const PROMOCODEVALID = "Please enter promocode!";
export const PROMOCODESTATUS = "Please select promocode status!";
export const PROMOCODEDISCOUNT = "Please enter promocode discount!";
export const PROMOCODESTARTDATE = "Please select promocode start date!";
export const PROMOCODEEXPIRYDATE = "Please select promocode expiration date!";
export const PROMOCODEUSAGE = "Please enter promocode usage!";
export const PROMOCODE_CREATE = "Promocode Added Successfully.";
export const PROMOCODE_UPDATE = "Promocode Updated Successfully.";
export const PROMOCODE_DELETE = "Promocode Deleted Successfully.";

//CourtManagement page
export const COURTMANAGE_COURT = "Please  select a court!";
export const COURTMANAGE_MEMBERRATE = "Please enter memberuser rate!";
export const COURTMANAGE_NONMEMBERRATE = "Please enter non-memberuser rate!";
export const COURTMANAGE_STARTDATE = "Please select the date!";
export const COURTMANAGE_ENDDATE = "Please select the end date!";
export const COURTMANAGE_STARTTIME = "Please select slot start time!";
export const COURTMANAGE_ENDTIME = "Please select slot end time!";
export const COURTMANAGE_CREATE = "CourtManagement Added Successfully.";
export const COURTMANAGE_UPDATE = "CourtManagement Updated Successfully.";
export const COURTMANAGE_DELETE = "CourtManagement Deleted Successfully.";

//AdminCreate Page
export const ADMIN_CREATE = "Admin Created Successfully.";
export const ADMIN_UPDATE = "Admin Updated Successfully.";
export const ADMIN_DELETE = "Admin Deleted Successfully.";

//memberShip Config
export const MEMBERSHIP_UPDATE = "MemberShip Rate Updated Successfully.";
export const MEMBERSHIP_ADDED = "MemberShip Rate Added Successfully.";
export const MEMBERSHIP_RATE = "Please enter memberShip rate!";
