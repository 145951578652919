import { useNavigate } from "react-router-dom";
import { Button } from "react-scroll";

function Pricing() {
  const navigate = useNavigate();

  return (
    <>
      <div className="publicheader">
        <div>
          <p className="publicheaderTitle">
            <center>Court Hire Fees</center>
          </p>
          <p className="publicheaderDescription">
            ( KBTC has two different price structures for court hire )
          </p>
        </div>
      </div>
      <div className="publicContent">
        <table id="pricingTable">
          <tbody>
            <tr>
              <td>
                <b>Off-Peak:</b>
              </td>
              <td>
                <center>Weekdays</center>
              </td>
              <td>
                <center>6am - 5pm</center>
              </td>
            </tr>
            <tr>
              <td>
                <b>Peak:</b>
              </td>
              <td>
                <center>Weekdays</center>
              </td>
              <td>
                <center>5pm - 10pm</center>
              </td>
            </tr>
            <tr>
              <td>
                <b>Peak:</b>
              </td>
              <td>
                <center>Weekends and Public Holidays</center>
              </td>
              <td>
                <center>6am - 10pm</center>
              </td>
            </tr>
          </tbody>
        </table>
        <table id="pricingMobileTable">
          <tbody>
            <tr className="pricingMobileTableTr">
              <td>Off-Peak:</td>
              <td>Peak:</td>
              <td>Peak:</td>
            </tr>
            <tr>
              <td>Weekdays</td>
              <td>Weekdays</td>
              <td>Weekends and Public Holidays</td>
            </tr>
            <tr>
              <td>6am - 5pm</td>
              <td>5pm - 10pm</td>
              <td>6am - 10pm</td>
            </tr>
          </tbody>
        </table>
        <br />
        <div className="tableHeader">
          <p className="tableTitle">Badminton Bookings</p>
        </div>
        <table id="pricingTable">
          <tbody>
            <tr>
              <td></td>
              <td>
                <center>
                  <b>Non-Members</b>
                </center>
              </td>
              <td>
                <center>
                  <b>Members</b>
                </center>
              </td>
            </tr>
            <tr>
              <td>
                <b>Weekdays Off-Peak Rate:</b>
              </td>
              <td>
                <center>$18 p/h</center>
              </td>
              <td>
                <center>$16 p/h</center>
              </td>
            </tr>
            <tr>
              <td>
                <b>Weekdays Peak Rate:</b>
              </td>
              <td>
                <center>$28 p/h</center>
              </td>
              <td>
                <center>$24 p/h</center>
              </td>
            </tr>
            <tr>
              <td>
                <b>Weekends and Public Holidays Rate:</b>
              </td>
              <td>
                <center>$28 p/h</center>
              </td>
              <td>
                <center>$24 p/h</center>
              </td>
            </tr>
          </tbody>
        </table>
        <table id="pricingMobileTable">
          <tbody>
            <tr className="pricingMobileTableTr">
              <td></td>
              <td>Non Members</td>
              <td>Members</td>
            </tr>
            <tr>
              <td className="pricingMobileTableTr">Weekdays Off-Peak Rate</td>
              <td>$18 p/h</td>
              <td>$16 p/h</td>
            </tr>
            <tr>
              <td className="pricingMobileTableTr">Weekdays Peak Rate</td>
              <td>$28 p/h</td>
              <td>$24 p/h</td>
            </tr>
            <tr>
              <td className="pricingMobileTableTr">
                Weekends and Public Holidays Rate:
              </td>
              <td>$28 p/h</td>
              <td>$24 p/h</td>
            </tr>
          </tbody>
        </table>
        <p>
          <center>
            *Please Note: Bookings once made are non-refundable, refer to our
            <a href="/terms&conditions" className="atag">
              <b style={{ color: "#1261ad" }}> Cancellation Policy</b>
            </a>
          </center>
        </p>

        <div className="buttonContent">
          <Button
            className="memberButton"
            onClick={() => {
              navigate("/login");
            }}
          >
            Online Booking Open Now Click Here
          </Button>
        </div>
      </div>
    </>
  );
}

export default Pricing;
