import React, { useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function MembershipFail() {
  const [countDown, setCountDown] = useState(5);

  useEffect(() => {
    const time = setInterval(() => {
      setCountDown((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearInterval(time);
  }, []);

  useEffect(() => {
    if (countDown === 0) {
      window.location.href = "/profile";
    }
  }, [countDown]);

  return (
    <>
      <div className="center">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <HighlightOffIcon
            className="scaling-icon"
            style={{ fontSize: 50, color: "#c91010" }}
          />
          <h1
            style={{
              color: "#c91010",
            }}
          >
            Membership Fail
          </h1>
          <p>
            You are redirecting to the profile page in {countDown} second...
          </p>{" "}
          <a
            style={{
              color: "#131d32",
              textDecoration: "none",
            }}
            href="profile"
          >
            Redirect Now
          </a>
        </div>
      </div>
    </>
  );
}
