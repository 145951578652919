import axios from "axios";
import { BASE_URL } from "../constants/Property";

const AxiosInstance = axios.create({
  baseURL: BASE_URL,
});

AxiosInstance.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem("authorizationToken");
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
  },
  (err) => Promise.reject(err)
);

AxiosInstance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalRequest = err.config;
    if (
      err.response &&
      err.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refreshToken");
      const data = await refreshAccessToken(refreshToken);
      if (data) {
        localStorage.setItem("authorizationToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        AxiosInstance.defaults.headers.Authorization = `Bearer ${data.accessToken}`;
        originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
        return AxiosInstance(originalRequest);
      } else {
        localStorage.removeItem("authorizationToken");
        window.location.href = "/login";
      }
    }
    return Promise.reject(err);
  }
);

const refreshAccessToken = async (refreshToken) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/auth/refresh`, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });
    return response.data;
  } catch (error) {
    localStorage.removeItem("authorizationToken");
    localStorage.removeItem("isValidAdmin");
    localStorage.removeItem("isMember");
    localStorage.removeItem("isValidUser");
    localStorage.removeItem("refreshToken");
    return null;
  }
};

export default AxiosInstance;
