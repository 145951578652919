import { ReactComponent as KBTCROUND } from "../../assets/kbtcround.svg";
import { ReactComponent as RACKETHALF } from "../../assets/rackethalf.svg";

function AboutUs() {
  return (
    <>
      <div className="publicheader">
        <p className="publicheaderTitle">ABOUT US</p>
        <KBTCROUND className="iconsvg" />
      </div>
      <div className="racketHalfIcon">
        <RACKETHALF style={{ width: 140 }} />
      </div>
      <div className="publicContent">
        <p>
          <b>Welcome to Krishna's Badminton Centre (KBTC)!</b>
        </p>
        <p>
          Located in the heart of Laverton North,
          <b> KBTC is your premier destination for badminton enthusiasts </b> in
          the western suburbs of Melbourne. Conveniently situated near
          Truganina, Tarneit, Laverton, Sunshine, Point Cook, Altona, Williams
          Landing, and Hoppers Crossing, we offer easy access to players from
          various neighbourhoods.
        </p>
        <p>
          At <b>KBTC</b>, we pride ourselves on providing a top-notch badminton
          experience. Our facility boasts
          <b>
            {" "}
            13 world-class courts featuring wooden sprung flooring with premium
            mats
          </b>
          , ensuring optimal performance and comfort for players of all levels.
        </p>
        <p>
          Looking to gear up for your next game?
          <b>
            {" "}
            Visit our Pro Shop, where you'll find a wide selection of
            merchandise, equipment, and restringing services to elevate your
            game to the next level.
          </b>{" "}
          Need a refreshment? Our on-site food and drinks refreshments have you
          covered.
        </p>
        <p>
          Parking is a breeze at <b> KBTC, with 41 car spaces available </b>
          within our fenced premises, along with plenty of street parking for
          added convenience. Our quiet and peaceful location provides the
          perfect setting for players to focus and enjoy their game to the
          fullest.
        </p>
        <p>
          In addition to our state-of-the-art courts,
          <b>
            {" "}
            we offer modern and excellent bathroom and shower facilities for
            men, women, and individuals with diverse abilities,
          </b>{" "}
          ensuring a comfortable and inclusive environment for all.
        </p>
        <p>
          Whether you're a seasoned pro or just starting,
          <b>
            {" "}
            KBTC welcomes badminton enthusiasts of all ages and skill levels.
          </b>{" "}
          Come join us and experience the thrill of badminton like never before
          at <b>Krishna's Badminton Centre.</b>
        </p>
      </div>
    </>
  );
}

export default AboutUs;
