import { ReactComponent as INSTAGRAM } from "../../assets/instagram.svg";
import { ReactComponent as FACEBOOK } from "../../assets/facebook.svg";
import { ReactComponent as TWITTER } from "../../assets/twittericon.svg";

function SocialClub() {
  return (
    <>
      <div className="publicheader">
        <p className="publicheaderTitle">KBTC Social & Club</p>
      </div>
      <div className="publicContent">
        <p>
          Get social with Krishna's Badminton Centre (KBTC) and stay connected
          with our vibrant badminton community both on and off the court!
          <b>
            {" "}
            At KBTC, we believe in the power of camaraderie and connection,{" "}
          </b>
          and we offer various opportunities for players to engage socially and
          follow us on social media.
        </p>
        <p>
          Join us for our regular social playing sessions, where players of all
          levels come together to enjoy the game in a relaxed and friendly
          atmosphere. It's a great way to meet new people, improve your skills,
          and have fun on the court.
        </p>
        <p>
          From tournament announcements to coaching tips and behind-the-scenes
          glimpses, our social media channels are your go-to source for all
          things badminton.
        </p>
        <p>
          <b>Share your badminton experiences with us</b> by tagging us in your
          posts and using <b>our official hashtag #KBTC.</b> We love seeing our
          community in action and sharing in your passion for the sport!
        </p>
        <p>
          <b>
            Follow us on Facebook and Instagram to stay updated on the latest
            news, events, and promotions at KBTC.
          </b>
        </p>
        <div className="socialIconContainer">
          <a
            href="https://www.instagram.com/kbtc.com.au/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <INSTAGRAM className="socialIcon" />
          </a>
          <a
            href="https://www.facebook.com/kbtc.com.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FACEBOOK className="socialIcon" />
          </a>
          <a
            href="https://x.com/kbtc_com_au"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TWITTER className="socialIcon" />
          </a>
        </div>
      </div>
    </>
  );
}

export default SocialClub;
