import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="white-box-home">
      <h1
        className="h2Booking"
        style={{ display: "flex", justifyContent: "center" }}
      >
        Privacy Policy
      </h1>
      <br />
      <p>
        Please read our Privacy policy described below on how we use, collect
        and disclose your information:
      </p>
      <ol>
        <li>
          When you visit our site for bookings, we collect some information
          <ul style={{ listStyleType: "none" }}>
            <br />
            <li>- to process your purchase.</li>
            <li>- to help you when you contact for support.</li>
            <li>- Provide you with invoices.</li>
            <li>
              - Provide you information/marketing information related to the
              Badminton facility.
            </li>
          </ul>
        </li>
        <br />
        <li>
          Personal information collected: Name, Billing/Shipping Address, Email
          ID, Contact details, and payment information.
        </li>
        <br />
        <li>
          All customer information is kept strictly confidential and will not be
          shared with third parties.
        </li>
        <br />
        <li>
          Security: Our site secures your personal information from any
          unauthorised access. Personal information you provide will be securely
          stored on computer servers in a secure and protected environment from
          any unauthorised access.
        </li>
        <br />
        <li>
          Use of cookies: Our site uses cookies to personalize your online
          experience. You have the ability to accept or decline cookies. Some
          web browsers automatically accept cookies, but you have the option to
          change the browser settings.
        </li>
        <br />
        <li>
          If you need any further information about our policy, please reach out
          to support@kbtc.com.au.
        </li>
      </ol>
    </div>
  );
}
