import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { DELETE_ALL_PENDING_BOOKINGS, LOGOUT, USER_ME } from "./constants/API";
import { deleteRequest, getRequest } from "./utils/CommonAxios";
import VerificationPage from "./components/auth/VerificationPage";
import IdleLogout from "./utils/IdelLogout";
import Header from "../src/components/Header/Header";
import LoginPage from "./components/auth/LoginPage";
import SignupPage from "./components/auth/signup";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import AdminLogin from "./components/auth/AdminLogin";
import PublicPage from "./pages/publicpage/PublicPage";
import TermsandConditions from "./pages/publicpage/TermsandConditions";
import PrivacyPolicy from "./pages/publicpage/PrivacyPolicy";
import {
  About,
  Checkout,
  Court,
  CourtManagement,
  MakeBooking,
  MyBooking,
  Profile,
  PromoCode,
  Users,
} from "./pages/pageListAsync/pageListAsync";
import BookingSuccess from "./pages/BookingSuccess";
import { message } from "antd";
import BookingFail from "./pages/BookingFail";
import MembershipFail from "./pages/MembershipFail";
import MembershipSuccess from "./pages/MembershipSuccess";

function App() {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState();
  const isAdmin = localStorage.getItem("isValidAdmin");
  const isUser = localStorage.getItem("isValidUser");

  const logout = async () => {
    const res = await getRequest(LOGOUT);
    if (res.payload.status === 200) {
      localStorage.removeItem("authorizationToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("isValidAdmin");
      localStorage.removeItem("isValidUser");
      localStorage.removeItem("isMember");
      navigate(isAdmin ? "/admin-login" : "/login");
    }
  };

  const fetchUserMe = async () => {
    try {
      const response = await getRequest(USER_ME);
      localStorage.setItem("isMember", response.payload.data.isMemberUser);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error.error,
      });
    }
  };

  const token = localStorage.getItem("authorizationToken");

  useEffect(() => {
    if (localStorage.getItem("authorizationToken")) {
      fetchUserMe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const deletePendingBookings = async () => {
    try {
      await deleteRequest(DELETE_ALL_PENDING_BOOKINGS);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error.error,
      });
    }
  };

  useEffect(() => {
    setPreviousPath(location.pathname);
    if (
      (previousPath === "/make-booking" &&
        location.pathname !== "/make-booking/checkout") ||
      location.pathname === "/make-booking" ||
      location.pathname === "/reserve-bookings" ||
      previousPath === "/reserve-bookings"
    ) {
      deletePendingBookings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const showHeader = () => {
    const publicPaths = [
      "/",
      "/login",
      "/signup",
      "/forgot-password",
      "/reset-password",
      "/verify-account",
      "/admin-login",
    ];
    return !publicPaths.includes(location.pathname);
  };

  useEffect(() => {
    if (
      token &&
      isUser &&
      (location.pathname === "/login" ||
        location.pathname === "/signup" ||
        location.pathname === "/forgot-password" ||
        location.pathname === "/reset-password" ||
        location.pathname === "/verify-account" ||
        location.pathname === "/admin-login")
    ) {
      navigate("/make-booking");
    } else if (
      token &&
      isAdmin &&
      (location.pathname === "/login" ||
        location.pathname === "/signup" ||
        location.pathname === "/forgot-password" ||
        location.pathname === "/reset-password" ||
        location.pathname === "/verify-account" ||
        location.pathname === "/admin-login")
    ) {
      navigate("/court");
    }
  }, [token, location.pathname, navigate, isAdmin, isUser]);

  return (
    <>
      {contextHolder}
      <div
        style={{
          background: "linear-gradient(to bottom, #fad928, white)",
          width: "100%",
          height: "100vh",
        }}
      >
        {token && <IdleLogout onLogout={logout} />}

        {showHeader() && (
          <Header
            userType={
              token && isAdmin ? "admin" : token && isUser ? "user" : "null"
            }
            logout={logout}
          />
        )}

        <Routes>
          <Route path="/" element={<PublicPage />} />
          <Route path="/terms&conditions" element={<TermsandConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-account" element={<VerificationPage />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          {isAdmin && (
            <>
              <Route path="/user" element={<Users />} />
              <Route path="/court" element={<Court />} />
              <Route path="/court-management" element={<CourtManagement />} />
              <Route path="/promocode" element={<PromoCode />} />
              <Route path="/reserve-bookings" element={<MakeBooking />} />
            </>
          )}
          {isUser && (
            <>
              <Route path="/profile" element={<Profile />} />
              <Route path="/about" element={<About />} />
              <Route path="/make-booking" element={<MakeBooking />} />
              <Route path="/my-bookings" element={<MyBooking />} />
              <Route path="/make-booking/*" element={<Checkout />} />
              <Route path="/booking-success" element={<BookingSuccess />} />
              <Route path="/booking-fail" element={<BookingFail />} />
              <Route
                path="/membership-success"
                element={<MembershipSuccess />}
              />
              <Route path="/membership-fail" element={<MembershipFail />} />
            </>
          )}
          <Route path="/*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
