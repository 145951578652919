import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bookings: {},
  bookingTime: {},
};

const booking = createSlice({
  name: "booking",
  initialState,
  reducers: {
    addBookings: (state, action) => {
      state.bookings = action.payload;
    },
    removeBookings: (state) => {
      state.bookings = {};
    },
    addBookingTime: (state, action) => {
      state.bookingTime = action.payload;
    },
    removeBookingTime: (state) => {
      state.bookingTime = {};
    },
  },
});

export const {
  addBookings,
  removeBookings,
  addBookingTime,
  removeBookingTime,
} = booking.actions;
export const getBookingIds = (state) => state.Booking.bookings;
export const getBookingTime = (state) => state.Booking.bookingTime;
export default booking.reducer;
