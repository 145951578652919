import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Button } from "antd";
import { ReactComponent as Logo } from "../../assets/krishna_logo.svg";
import { ReactComponent as PlayerIcon } from "../../assets/player.svg";
import { ReactComponent as CalendarIcon } from "../../assets/calander.svg";
import { ReactComponent as HomeIcon } from "../../assets/home.svg";
import { ReactComponent as ShuttleIcon } from "../../assets/suttle.svg";

function Header({ userType, logout }) {
  const navigate = useNavigate();

  function toggleMobileNav() {
    var navLinks = document.querySelector(".nav-links");
    navLinks.classList.toggle("mobile");
  }

  function closeMobileNav() {
    var navLinks = document.querySelector(".nav-links");
    navLinks.classList.remove("mobile");
  }

  const navLinksData = {
    user: [
      { to: "/", icon: <HomeIcon />, text: "Home" },
      { to: "/profile", icon: <PlayerIcon />, text: "Profile" },
      { to: "/make-booking", icon: <CalendarIcon />, text: "Make a Booking" },
      { to: "/my-bookings", icon: <CalendarIcon />, text: "My Bookings" },
      { to: "/about", icon: <ShuttleIcon />, text: "About" },
    ],
    admin: [
      { to: "/user", icon: <HomeIcon />, text: "Users" },
      { to: "/court", icon: <HomeIcon />, text: "Court" },
      {
        to: "/court-management",
        icon: <PlayerIcon />,
        text: "Court Management",
      },
      { to: "/promocode", icon: <CalendarIcon />, text: "Rate Configuration" },
      {
        to: "/reserve-bookings",
        icon: <CalendarIcon />,
        text: "Total Bookings",
      },
    ],
  };

  const userTypeLinks = navLinksData[userType] || [];

  return userType !== "null" ? (
    <div className="nav">
      <div>
        <Logo
          className="logo"
          title="kbtc.com.au"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="mobile-nav">
        <FontAwesomeIcon
          style={{ color: "white", marginRight: 10 }}
          icon={faBars}
          onClick={toggleMobileNav}
        />
        <div className="nav-links">
          {userTypeLinks.map((link, index) => (
            <NavLink
              key={index}
              to={link.to}
              activeclassname="active"
              className="nav-link"
              onClick={closeMobileNav}
            >
              <div className="icon-container">
                <div className="icon">{link.icon}</div>
                <span className="icon-text">{link.text}</span>
              </div>
            </NavLink>
          ))}
          <Button
            style={{ float: "right", verticalAlign: "baseline" }}
            onClick={logout}
          >
            Log out
          </Button>
        </div>
      </div>
      <div className="nav-links">
        {userTypeLinks.map((link, index) => (
          <NavLink
            key={index}
            to={link.to}
            activeclassname="active"
            className="nav-link"
            onClick={closeMobileNav}
          >
            <div className="icon-container">
              <span className="icon">{link.icon}</span>
              <span className="icon-text">{link.text}</span>
            </div>
          </NavLink>
        ))}
        <Button
          style={{ float: "right", verticalAlign: "baseline" }}
          onClick={logout}
        >
          Log out
        </Button>
      </div>
    </div>
  ) : (
    <div className="nav">
      <div>
        <Logo
          className="logo"
          title="kbtc.com.au"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  );
}

export default Header;
