import React from "react";
import Navebar from "../../components/PublicPage/Navbar.js";
import Footer from "../../components/PublicPage/Footer.js";
import AboutUs from "../../components/PublicPage/About.js";
import Membership from "../../components/PublicPage/Membership.js";
import SocialClub from "../../components/PublicPage/SocialandClub.js";
import Pricing from "../../components/PublicPage/Pricing.js";
import Home from "../../components/PublicPage/Home.js";
import Contactus from "../../components/PublicPage/Contactus.js";

export default function PublicPage() {
  return (
    <>
      <Navebar />
      <div id="home" className="background-home">
        <Home />
      </div>
      <div id="about-us" className="background-about">
        <AboutUs />
      </div>
      <div id="membership" className="background-membership">
        <Membership />
      </div>
      <div id="pricing" className="background-pricing">
        <Pricing />
      </div>
      <div id="social-&-club" className="background-socialclub">
        <SocialClub />
      </div>
      <div id="contact-us" className="background-contactus">
        <Contactus />
      </div>
      <Footer />
    </>
  );
}
