const validateField = (
  value,
  errorSetter,
  errorValue,
  regex = null,
  regexErrorValue = null,
  additionalCheck = false,
  isCheckbox = false
) => {
  if (isCheckbox) {
    if (!value) {
      errorSetter(errorValue);
      return false;
    } else {
      errorSetter();
      return true;
    }
  }
  if (!value || value.trim() === "") {
    errorSetter(errorValue);
    return false;
  } else if (regex && !regex.test(value)) {
    errorSetter(regexErrorValue);
    return false;
  } else if (additionalCheck) {
    errorSetter(regexErrorValue);
    return false;
  } else {
    errorSetter();
    return true;
  }
};

export default validateField;
