/* eslint-disable no-unused-vars */
import { Input, message } from "antd";
import { ReactComponent as AVATAR } from "../../assets/contactavatar.svg";
import { ReactComponent as RACKET } from "../../assets/rackethalf2.svg";
import TextArea from "antd/es/input/TextArea";
import { Button } from "react-scroll";
import validateField from "../../utils/ValidateField";
import {
  EMAIL,
  FIRSTNAME,
  LASTNAME,
  MESSAGE,
  MOBILENUMBER,
  VALIDATE_EMAIL,
  VALIDATE_FIRSTNAME,
  VALIDATE_LASTNAME,
  VALIDATE_MOBILE,
} from "../../constants/messages";
import { postRequest } from "../../utils/CommonAxios";
import { CONTACTUS } from "../../constants/API";
import { useState } from "react";

function Contactus() {
  const [messageApi, contextHolder] = message.useMessage();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [messages, setMessages] = useState("");

  const [firstnameError, setFirstnameError] = useState("");
  const [lastnameError, setLastnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [messageError, setMessageError] = useState("");

  const validateForm = () => {
    const validationResults = [];
    validationResults.push(
      validateField(
        firstname,
        setFirstnameError,
        FIRSTNAME,
        /^[a-zA-Z]{1,20}$/,
        VALIDATE_FIRSTNAME
      )
    );
    validationResults.push(
      validateField(
        lastname,
        setLastnameError,
        LASTNAME,
        /^[a-zA-Z]{1,20}$/,
        VALIDATE_LASTNAME
      )
    );
    validationResults.push(
      validateField(
        email,
        setEmailError,
        EMAIL,
        // eslint-disable-next-line
        /^[\w.\+\-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,
        VALIDATE_EMAIL
      )
    );
    validationResults.push(
      validateField(
        phone,
        setPhoneError,
        MOBILENUMBER,
        /^\d{10}$/,
        VALIDATE_MOBILE
      )
    );
    validationResults.push(validateField(messages, setMessageError, MESSAGE));
    const isValid = !validationResults.includes(false);
    return isValid;
  };
  const clearForm = () => {
    setFirstname("");
    setLastname("");
    setEmail("");
    setMessages("");
    setPhone("");
  };

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const requestBody = {
      firstname,
      lastname,
      email,
      phone,
      messages,
    };

    postRequest(CONTACTUS, requestBody)
      .then((response) => {
        clearForm();
        messageApi.open({
          type: "success",
          content: "Your Message has been sent successfully",
        });
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error.error,
        });
      });
  };

  return (
    <>
      {contextHolder}
      <div className="contactHeader">
        <RACKET className="contactRacket" />
        <p className="contactTitle">Get In Touch</p>
      </div>
      <div className="contactSpacing">
        <div className="contactContent">
          <span>
            We'd love to hear from you! Whether you have a question, want to
            book a court,
          </span>
          <br />
          <span>
            {" "}
            or simply want to learn more about what{" "}
            <b> Krishna's Badminton Centre (KBTC) </b>has to offer,
            <br />
            our team is here to assist you.
          </span>
          <br />
          {/* <p>
            <b style={{ fontSize: 20 }}>
              <u>Your Contact Information</u>
            </b>
          </p>
          <strong style={{ fontSize: 30 }}>Contact Information</strong> */}

          <br />
          <a
            href="tel:+61394488955"
            className="atag"
            style={{ color: "#1261ad", fontSize: "20px" }}
          >
            <b> Contact No: 03 9448 8955 </b>
          </a>
          <br />
          <a
            href="mailto:support@kbtc.com.au"
            className="atag"
            style={{ color: "#1261ad", fontSize: "20px" }}
          >
            <b> Email Address: support@kbtc.com.au</b>
          </a>
        </div>
        {/* <form style={{ fontWeight: "bold", color: "#1261ad" }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div className="contactFieldStyle">
              <label htmlFor="firstName">First Name*</label>
              <Input
                placeholder="Enter your first name"
                className="contactInput"
                style={{ marginRight: 15 }}
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="fieldErrorMessage">{firstnameError}</span>
            </div>
            <div className="contactFieldStyle">
              <label htmlFor="lastname">Last Name*</label>
              <Input
                placeholder="Enter your last name"
                className="contactInput"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="fieldErrorMessage">{lastnameError}</span>
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div className="contactFieldStyle">
              <label htmlFor="email">Email Address*</label>
              <Input
                placeholder="Enter your email"
                className="contactInput"
                style={{ marginRight: 15 }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="fieldErrorMessage">{emailError}</span>
            </div>
            <div className="contactFieldStyle">
              <label htmlFor="number">Phone Number*</label>
              <Input
                placeholder="Enter your phone number"
                className="contactInput"
                value={phone}
                maxLength={10}
                onChange={(e) => setPhone(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="fieldErrorMessage">{phoneError}</span>
            </div>
          </div>
          <div className="contactFieldStyle">
            <label htmlFor="message">Message*</label>
            <TextArea
              placeholder="Type your message here....."
              className="contactInput"
              value={messages}
              onChange={(e) => setMessages(e.target.value)}
              onBlur={(e) => validateForm(e.target.value)}
            />
            <span className="fieldErrorMessage">{messageError}</span>
          </div>
          <div className="contactFieldStyle">
            <Button
              className="contactSubmit"
              // htmlType="submit"
              // onClick={handleSubmit}
            >
              <b>Submit</b>
            </Button>
          </div>
        </form> */}
        <AVATAR className="contactavatar" />
      </div>
    </>
  );
}

export default Contactus;
