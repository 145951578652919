import { Button, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const IdleLogout = ({ timeout = 1800000, onLogout }) => {
  const [showPopup, setShowPopup] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let timer;
    const events = ["mousemove", "keydown", "mousedown", "touchstart"];

    const resetIdleTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (location.pathname === "/") {
          localStorage.removeItem("authorizationToken");
          localStorage.removeItem("isValidAdmin");
          localStorage.removeItem("isMember");
          localStorage.removeItem("isValidUser");
          localStorage.removeItem("refreshToken");
        } else {
          setShowPopup(true);
          localStorage.setItem("sessionTimeOut", true);
        }
      }, timeout);
    };

    window.onload = () => {
      const sessionTimedOut = localStorage.getItem("sessionTimeOut");
      if (sessionTimedOut) {
        localStorage.removeItem("authorizationToken");
        localStorage.removeItem("isValidAdmin");
        localStorage.removeItem("isMember");
        localStorage.removeItem("isValidUser");
        localStorage.removeItem("sessionTimeOut");
        localStorage.removeItem("refreshToken");
      }
    };

    const handleActivity = () => {
      resetIdleTimer();
    };

    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    resetIdleTimer();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });

      clearTimeout(timer);
    };
  }, [location.pathname, onLogout, timeout]);

  const handleLogout = () => {
    setShowPopup(false);
    localStorage.removeItem("sessionTimeOut");
    onLogout();
  };

  return (
    <Modal
      title="Session Timeout"
      open={showPopup}
      footer={[
        <Button key="ok" type="primary" onClick={handleLogout}>
          Ok
        </Button>,
      ]}
      closable={false}
    >
      <span>
        Your session has expired due to inactivity. Please Login again.
      </span>
    </Modal>
  );
};

export default IdleLogout;
