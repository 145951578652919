import Loading from "../../components/Loading";
import loadable from "../../utils/loadable";

export const MakeBooking = loadable(() => import("../MakeBooking"), {
  fallback: <Loading />,
});

export const MyBooking = loadable(() => import("../MyBooking"), {
  fallback: <Loading />,
});

export const About = loadable(() => import("../About"), {
  fallback: <Loading />,
});

export const Checkout = loadable(() => import("../Checkout"), {
  fallback: <Loading />,
});

export const HomePage = loadable(() => import("../HomePage"), {
  fallback: <Loading />,
});

export const Profile = loadable(() => import("../Profile"), {
  fallback: <Loading />,
});

export const Court = loadable(() => import("../admin/Court"), {
  fallback: <Loading />,
});

export const CourtManagement = loadable(
  () => import("../admin/CourtManagement"),
  {
    fallback: <Loading />,
  }
);

export const PromoCode = loadable(() => import("../admin/PromoCode"), {
  fallback: <Loading />,
});

export const Users = loadable(() => import("../admin/Users"), {
  fallback: <Loading />,
});
