import { ReactComponent as SMS } from "../../assets/smsicon.svg";
import { ReactComponent as PHONE } from "../../assets/phoneicon.svg";
import { ReactComponent as MAP } from "../../assets/mapicon.svg";
import { ReactComponent as KBTC } from "../../assets/kbtcround.svg";
import { ReactComponent as PHONEM } from "../../assets/phoneMobileIcon.svg";
import { ReactComponent as MAPM } from "../../assets/mapMobileIcon.svg";
import { ReactComponent as SMSM } from "../../assets/smsMobileIcon.svg";

import { Button } from "react-scroll";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  return (
    <>
      <div className="contentContainer">
        <div className="spacing">
          <a href="mailto:support@kbtc.com.au" className="atag">
            <div className="boxContainer">
              <SMS className="smsIcon" />
              <p className="fontStyle1">support@kbtc.com.au</p>
            </div>
          </a>
          <a href="tel:+61394488955" className="atag">
            <div className="boxContainer">
              <PHONE className="smsIcon" />
              <p className="fontStyle2">03 9448 8955</p>
            </div>
          </a>
        </div>
        <div className="spacing">
          <KBTC className="kbtcIcon" />
          <a
            href="https://www.google.com/maps/place/50+E+Cherry+Ln,+Laverton+North+VIC+3026,+Australia/@-37.8349588,144.7982513,17z/data=!3m1!4b1!4m6!3m5!1s0x6ad661e96fc61b23:0xf16e05cd02641c2d!8m2!3d-37.8349631!4d144.8008262!16s%2Fg%2F11sw3l033t?entry=ttu"
            className="atag"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="boxContainer">
              <MAP className="mapIcon" />

              <p className="fontStyle1">
                50E Cherry Lane, <p style={{ margin: 0 }}>Laverton North</p>
              </p>
            </div>
          </a>
          <a
            href="https://www.google.com/maps/place/50+E+Cherry+Ln,+Laverton+North+VIC+3026,+Australia/@-37.8349588,144.7982513,17z/data=!3m1!4b1!4m6!3m5!1s0x6ad661e96fc61b23:0xf16e05cd02641c2d!8m2!3d-37.8349631!4d144.8008262!16s%2Fg%2F11sw3l033t?entry=ttu"
            className="atag"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="boxContainer"
              style={{ width: "50%", float: "right", marginRight: 20 }}
            >
              <p className="fontStyle2">Victoria 3026</p>
            </div>
          </a>
        </div>
      </div>
      <Button
        className="bookButton"
        onClick={() => {
          navigate("/login");
        }}
      >
        Online Booking Open Now Click Here
      </Button>

      <div className="mobileView">
        <div className="mobileBoxContainer">
          <a
            href="https://www.google.com/maps/place/50+E+Cherry+Ln,+Laverton+North+VIC+3026,+Australia/@-37.8349588,144.7982513,17z/data=!3m1!4b1!4m6!3m5!1s0x6ad661e96fc61b23:0xf16e05cd02641c2d!8m2!3d-37.8349631!4d144.8008262!16s%2Fg%2F11sw3l033t?entry=ttu"
            className="atag"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div style={{ margin: 10 }}>
              <MAPM className="mapMobileIcon" />
              <p className="fontStyle3">
                50E Cherry Lane,
                <p style={{ margin: 0 }}> Laverton North, Victoria 3026</p>
              </p>
            </div>
          </a>
        </div>
        <div className="mobileBoxContainer" style={{ background: "#ffffff10" }}>
          <div>
            <PHONEM className="phoneMobileIcon" />
            <a href="tel:+61394488955" className="atag">
              <p className="fontStyle4">03 9448 8955</p>
            </a>
          </div>
        </div>
        <div>
          <SMSM className="smsMobileIcon" />
          <a href="mailto:support@kbtc.com.au" className="atag">
            <p className="fontStyle4">support@kbtc.om.au</p>
          </a>
        </div>
        <Button
          className="bookButtonMobile"
          onClick={() => {
            navigate("/login");
          }}
        >
          Online Booking Open Now Click Here
        </Button>
      </div>
    </>
  );
}

export default Home;
