import React, { useState } from "react";
import { Button, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { RESEND_LINK, SIGNUP } from "../../constants/API";
import "../../style.css";
import { postRequest, putRequest } from "../../utils/CommonAxios";
import { ReactComponent as KbtcIocn } from "../../assets/KbtcYellow.svg";
import { Box, Container } from "@mui/material";
import {
  CHECKBOX,
  CONFIRMPASSWORD,
  EMAIL,
  FIRSTNAME,
  LASTNAME,
  MATCHPASSWORD,
  MOBILENUMBER,
  PASSWORD,
  POSTCODE,
  SIGNUP_SUCCESS,
  VALIDATE_EMAIL,
  VALIDATE_FIRSTNAME,
  VALIDATE_LASTNAME,
  VALIDATE_MOBILE,
  VALIDATE_PASSWORD,
  VALIDATE_POSTCODE,
} from "../../constants/messages";
import CircularProgress from "@mui/material/CircularProgress";
import validateField from "../../utils/ValidateField";

export default function SignupPage() {
  const [messageApi, contextHolder] = message.useMessage();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [postCode, setPostCode] = useState();
  const [mobile, setMobile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [emailError, setEmailError] = useState("");
  const [firstnameError, setFirstnameError] = useState("");
  const [lastnameError, setLastnameError] = useState("");
  const [postCodeError, setPostCodeError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptTermsError, setAcceptTermsError] = useState("");

  const navigate = useNavigate();

  const validateForm = () => {
    const validationResults = [];
    validationResults.push(
      validateField(
        email,
        setEmailError,
        EMAIL,
        // eslint-disable-next-line
        /^[\w.\+\-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,
        VALIDATE_EMAIL
      )
    );
    validationResults.push(
      validateField(
        firstname,
        setFirstnameError,
        FIRSTNAME,
        /^[a-zA-Z]{1,20}$/,
        VALIDATE_FIRSTNAME
      )
    );
    validationResults.push(
      validateField(
        lastname,
        setLastnameError,
        LASTNAME,
        /^[a-zA-Z]{1,20}$/,
        VALIDATE_LASTNAME
      )
    );
    validationResults.push(
      validateField(
        mobile,
        setMobileError,
        MOBILENUMBER,
        /^\d{10}$/,
        VALIDATE_MOBILE
      )
    );
    validationResults.push(
      validateField(
        postCode,
        setPostCodeError,
        POSTCODE,
        /^\d{4}$/,
        VALIDATE_POSTCODE
      )
    );
    validationResults.push(
      validateField(
        password,
        setPasswordError,
        PASSWORD,
        /^.{8,}$/,
        VALIDATE_PASSWORD
      )
    );
    validationResults.push(
      validateField(
        confirmPassword,
        setConfirmPasswordError,
        CONFIRMPASSWORD,
        null,
        password !== confirmPassword ? MATCHPASSWORD : null,
        password !== confirmPassword
      )
    );
    validationResults.push(
      validateField(
        acceptTerms,
        setAcceptTermsError,
        CHECKBOX,
        null,
        null,
        null,
        true
      )
    );
    const isValid = !validationResults.includes(false);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const valid = validateForm();
    if (valid) {
      signUp();
    }
  };

  const signUp = async () => {
    const data = {
      firstName: firstname,
      lastName: lastname,
      email: email,
      password,
      confirmPassword,
      postCode: Number(postCode),
      mobile,
    };
    setIsLoading(true);
    postRequest(SIGNUP, data)
      .then(() => {
        setSuccessMsg(SIGNUP_SUCCESS);
      })
      .then(() => {
        setIsDisable(true);
        setIsLoading(false);
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error.error,
        });
        setIsLoading(false);
      });
  };

  const redirectToLogin = () => {
    navigate("/login");
  };

  const resendlink = async () => {
    const data = {
      email: email,
    };
    try {
      await putRequest(RESEND_LINK, data);
      messageApi.open({
        type: "success",
        content: "Verification link resend successfully.",
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error.error,
      });
    }
  };

  return (
    <>
      <Container
        maxWidth="xs"
        sx={{
          maxWidth: "450px",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {contextHolder}
        <Box>
          <div className="white-box">
            <div className="image-container">
              <span className="loginLogo">
                <KbtcIocn />
              </span>
            </div>
            <h3 className="h1h2Login">Signup Form</h3>
            <center>
              <p className="loginText">
                Please fill the following details. All fields marked with * are
                required.
              </p>
            </center>
            <form onSubmit={handleSubmit}>
              <Input
                placeholder="* Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="errorMessage">{emailError}</span>
              <br />
              <br />
              <Input
                placeholder="* First Name"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="errorMessage">{firstnameError}</span>
              <br />
              <br />
              <Input
                placeholder="Last Name"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="errorMessage">{lastnameError}</span>
              <br />
              <br />
              <Input
                placeholder="* Mobile"
                value={mobile}
                maxLength={10}
                onChange={(e) => setMobile(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="errorMessage">{mobileError}</span>
              <br />
              <br />
              <Input
                placeholder="* Post Code"
                value={postCode}
                maxLength={4}
                onChange={(e) => setPostCode(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="errorMessage">{postCodeError}</span>
              <br />
              <br />
              <Input
                placeholder="* Password (at least 8 characters long)"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="errorMessage">{passwordError}</span>
              <br />
              <br />
              <Input
                placeholder="* Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="errorMessage">{confirmPasswordError}</span>
              <br />
              <br />
              <label className="loginText">
                <input
                  type="checkbox"
                  value={acceptTerms}
                  checked={acceptTerms}
                  onChange={(e) => setAcceptTerms(e.target.checked)}
                />{" "}
                <a
                  href="/terms&conditions"
                  className="atag"
                  style={{ color: "#ffe453" }}
                  target="_blank"
                >
                  I accept the Terms & Condition
                </a>
              </label>
              <br />
              <span className="errorMessage">{acceptTermsError}</span>
              <br />
              <br />
              {successMsg && (
                <div className="alert alert-success alert-dismissable margin-top: 20px;">
                  {successMsg}
                </div>
              )}
              <Button
                className="buttonLogin"
                htmlType="submit"
                disabled={isLoading || isDisable || !acceptTerms}
                style={{ backgroundColor: isLoading ? "#fade43" : "#fade43" }}
              >
                {isLoading ? (
                  <CircularProgress
                    color="primary"
                    loading={isLoading.toString()}
                    size={26}
                  ></CircularProgress>
                ) : isDisable ? (
                  <>Signup</>
                ) : (
                  <>Signup</>
                )}
              </Button>
            </form>
            <br />
            <center>
              {successMsg && (
                <p className="loginText">
                  Resend Verification Link
                  <span className="aLogin" onClick={resendlink}>
                    Click here
                  </span>
                </p>
              )}
              <p className="loginText">
                Already have a login?
                <a href="login" className="aLogin" onClick={redirectToLogin}>
                  Sign in here
                </a>
              </p>
            </center>
          </div>
        </Box>
      </Container>
    </>
  );
}
