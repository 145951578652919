import { Spin } from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

function Loading() {
  return (
    <div className="overlay">
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 50,
            }}
          />
        }
        fullscreen
      />
    </div>
  );
}

export default Loading;
