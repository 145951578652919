import React, { useState } from "react";
import { Button, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { RESET_PASSWORD } from "../../constants/API";
import "../../style.css";
import { postRequest } from "../../utils/CommonAxios";
import { ReactComponent as KbtcIocn } from "../../assets/KbtcYellow.svg";
import { Box, Container } from "@mui/material";
import {
  CONFIRMPASSWORD,
  MATCHPASSWORD,
  PASSWORD,
  PASSWORD_UPDATE_SUCCESS,
  VALIDATE_PASSWORD,
} from "../../constants/messages";
import validateField from "../../utils/ValidateField";

export default function ResetPassword() {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);

  const [messageApi, contextHolder] = message.useMessage();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const redirectToSignup = () => {
    navigate("/signup");
  };

  const validateForm = () => {
    const validationResults = [];
    validationResults.push(
      validateField(
        password,
        setPasswordError,
        PASSWORD,
        /^.{8,}$/,
        VALIDATE_PASSWORD
      )
    );
    validationResults.push(
      validateField(
        confirmPassword,
        setConfirmPasswordError,
        CONFIRMPASSWORD,
        null,
        password !== confirmPassword ? MATCHPASSWORD : null,
        password !== confirmPassword
      )
    );
    const isValid = !validationResults.includes(false);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const valid = validateForm();

    if (valid) {
      handleResetPassword();
    }
  };

  const handleResetPassword = async () => {
    const email = urlParams.get("email");
    const token = urlParams.get("token");

    const data = {
      email: email,
      token: token,
      password: password,
    };
    postRequest(RESET_PASSWORD, data)
      .then((response) => {
        setSuccessMsg(PASSWORD_UPDATE_SUCCESS);
        setIsDisable(true);
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error.error,
        });
      });
  };

  return (
    <>
      <Container
        maxWidth="xs"
        sx={{
          maxWidth: "450px",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {contextHolder}
        <Box>
          <div className="white-box">
            <span className="loginLogo">
              <KbtcIocn />
            </span>
            <h3 className="h1h2Login">Reset Password</h3>
            <center>
              <p className="loginText">
                Please enter your new password details for update password.
              </p>
            </center>
            <form onSubmit={handleSubmit}>
              <Input
                className="inputField"
                placeholder="enter new password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="errorMessage">{passwordError}</span>
              <br />
              <br />
              <Input
                className="inputField"
                placeholder="confirm password"
                value={confirmPassword}
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="errorMessage">{confirmPasswordError}</span>
              <br />
              <br />
              <Button
                className="buttonLogin"
                htmlType="submit"
                disabled={isDisable}
                style={{ backgroundColor: isDisable ? "#fade43" : "#fade43" }}
              >
                {isDisable ? <>Reset</> : <>Reset</>}
              </Button>
            </form>
            <br />
            <center>
              {successMsg && (
                <div className="alert alert-success alert-dismissable margin-top: 20px;">
                  {successMsg}
                </div>
              )}
              <p className="loginText">
                <a className="aLogin" href="login">
                  Login here
                </a>
              </p>
              <p className="loginText">
                Are you new?
                <a href="signup" className="aLogin" onClick={redirectToSignup}>
                  Signup here
                </a>
              </p>
            </center>
          </div>
        </Box>
      </Container>
    </>
  );
}
