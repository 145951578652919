import React, { useEffect, useState } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { VERIFY_ACCOUNT } from "../../constants/API";
import { getRequest } from "../../utils/CommonAxios";
import { CircularProgress } from "@mui/material";

export default function VerificationPage() {
  const urlParams = new URLSearchParams(window.location.search);

  const [success, setSuccess] = useState();
  const [falied, setFailed] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleVerifyAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVerifyAccount = async () => {
    const email = urlParams.get("email");
    const token = urlParams.get("token");

    const data = {
      email: email,
      token: token,
    };

    getRequest(VERIFY_ACCOUNT, data)
      .then((response) => {
        if (response.payload.status === 200) {
          setSuccess("Account verified");
        }
      })
      .catch((error) => {
        setFailed(error.error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="center">
        {isLoading ? (
          <div>
            <CircularProgress
              color="primary"
              loading={isLoading}
              size={40}
            ></CircularProgress>
          </div>
        ) : success ? (
          <div>
            <TaskAltIcon
              className="scaling-icon"
              style={{ fontSize: 50, color: "#0858a4", marginLeft: 150 }}
            />
            <h1 style={{ color: "#0858a4" }}>Verification Successful</h1>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              You can login now
              <a
                style={{
                  color: "#0858a4",
                  textDecoration: "none",
                  marginLeft: 10,
                }}
                href="login"
              >
                Login here
              </a>
            </p>
          </div>
        ) : (
          <div>
            <h1
              style={{
                color: "red",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Verification Failed
            </h1>
            <p
              style={{
                color: "#0858a4",
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {falied}
            </p>
            <a
              style={{
                color: "#0858a4",
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
              }}
              href="signup"
            >
              SignUp here
            </a>
          </div>
        )}
      </div>
    </>
  );
}
