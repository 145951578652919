import React, { useState } from "react";
import { Button, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../constants/API";
import "../../style.css";
import { ReactComponent as KbtcIocn } from "../../assets/KbtcYellow.svg";
import { postRequest } from "../../utils/CommonAxios";
import { Box, Container } from "@mui/material";
import { PASSWORD, USERNAME } from "../../constants/messages";
import validateField from "../../utils/ValidateField";

export default function LoginPage() {
  const [messageApi, contextHolder] = message.useMessage();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    const validationResults = [];
    validationResults.push(validateField(email, setEmailError, USERNAME));
    validationResults.push(validateField(password, setPasswordError, PASSWORD));
    const isValid = !validationResults.includes(false);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const valid = validateForm();
    if (valid) {
      logMeIn();
    }
  };

  const logMeIn = async () => {
    const data = {
      email,
      password,
    };
    setIsLoading(true);
    postRequest(LOGIN, data)
      .then((response) => {
        localStorage.setItem("isValidUser", "true");
        localStorage.setItem(
          "authorizationToken",
          response.payload.data.accessToken
        );
        localStorage.setItem(
          "refreshToken",
          response.payload.data.refreshToken
        );
        setIsLoading(false);
        navigate("/profile");
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error.error,
        });
        setIsLoading(false);
      });
  };

  const redirectToSignup = () => {
    navigate("/signup");
  };

  return (
    <>
      <Container
        maxWidth="xs"
        sx={{
          maxWidth: "450px",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {contextHolder}
        <Box>
          <div className="white-box">
            <div className="image-container"></div>
            <span
              className="loginLogo"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            >
              <KbtcIocn />
            </span>
            {/* <span className="reggaeOneFont">Sports</span> */}
            {/* <br /> */}
            <center>
              <p className="loginText">
                Welcome. Please login to the Customer Portal here.
              </p>
            </center>
            <form onSubmit={handleSubmit}>
              <Input
                placeholder="username"
                className="inputField"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="errorMessage">{emailError}</span>
              <br />
              <br />
              <Input
                placeholder="password"
                className="inputField"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="errorMessage">{passwordError}</span>
              <br />
              <br />
              <Button
                className="buttonLogin"
                htmlType="submit"
                loading={isLoading}
              >
                Login
              </Button>
            </form>
            <br />
            <center>
              <p className="loginText">
                Forgot password?
                <a className="aLogin" href="forgot-password">
                  Forgot password
                </a>
              </p>
              <p className="loginText">
                Are you new?
                <a href="signup" className="aLogin" onClick={redirectToSignup}>
                  Signup here
                </a>
              </p>
            </center>
          </div>
        </Box>
      </Container>
    </>
  );
}
