import React from "react";

export default function TermsandConditions() {
  return (
    <div className="white-box-home">
      <h1 className="h2Booking">Terms and Conditions</h1>
      <br />
      <div className="hometext2">
        <p>
          All patrons accessing the facility must follow all our rules and
          regulations prior to use of the centre. These rules & regulations are
          designed to allow all participants to enjoy our centre in a way that
          will be safe and comply with all regulations.
        </p>
        <p>
          KBTC reserves the right to change these Rules & Regulations from time
          to time in addition to make, vary and revoke any by-laws which may be
          considered necessary or desirable for the regulation and the conduct
          of the participants.
        </p>
        <p>Any such changes will be notified to participants immediately.</p>
        <br />
        <span className="hometext3">Cancellations & Refunds </span>
        <ul>
          <li>
            Cancellations can be done only before 24 hrs prior to the booking
            time. Credit will be raised to use it in the future. A recurring
            booking can be rescheduled within 30 days from the date of
            cancellation. For refunds, the customer has to fill out the refund
            form available at the reception. No refunds will be issued if the
            booking was cancelled 24 hrs prior to booking time.
          </li>
        </ul>
        <br />
        <span className="hometext3">General </span>
        <ul>
          <li>
            Court Hire can be made by booking online, calling or Walk-in subject
            to court availability. All fees must be paid before using
            facilities.
          </li>
          <br />
          <li>Please verify your booking time before accessing the courts.</li>
          <br />
          <li>
            Participants are not allowed to bring any food & beverages onto the
            badminton courts.
          </li>
          <br />
          <li>
            Only Authorised coaches are permitted to coach and use any
            equipment. Unauthorised coaching/lessons are not permitted at the
            premises.
          </li>
          <br />
          <li>
            KBTC Strictly prohibits smoking, Vaping, and any illicit goods/
            drugs or animals in the facility. Anyone found using any of these
            will be required to leave the premises immediately and relevant
            authorities will be notified.
          </li>
          <br />
          <li>
            Children under the age of 12 must be under adult supervision at all
            times.
          </li>
          <br />
          <li>
            KBTC is not responsible for any lost or stolen items. Items left on
            the premises will be kept at reception for one week. After that,
            items will be discarded or donated to charity.
          </li>
        </ul>
        <br />
        <span className="hometext3">Patrons using the facility Must NOT</span>
        <ul>
          <li>
            cause any Nuisance or interfere with the safe use or enjoyment of
            the club by other participants.
          </li>
          <br />
          <li>
            Use any Obscene language, gestures, swearing, profanity, blasphemy,
            Harassment and other offensive behaviour. Any such behaviour will
            prompt the management to ask the persons to leave the centre
            immediately and may be reported to the relevant authorities.
          </li>
        </ul>
        <br />
        <span className="hometext3">Dress Code</span>
        <ul>
          <li>
            Patrons are expected to wear suitable sports clothing while using
            the centre. KBTC reserves the right to deny entry to any person if
            KBTC staff consider the attire to be inappropriate with centre
            rules.
          </li>
          <br />
          <li>
            Patrons using the facility must wear appropriate indoor sports shoes
            and KBTC reserves the right to deny entry to any person trying to
            enter the facility with bare feet, casual boots, work boots, hiking
            shoes, or soccer/ footy shoes.
          </li>
        </ul>
        <br />
        <span className="hometext3">Safety & health</span>
        <ul>
          <li>
            KBTC management or staff have no expertise in treating any medical
            conditions/diagnosing. Please consult your GP if you have any
            symptoms before coming to the facility.
          </li>
          <br />
          <li>
            KBTC will request the person not to use any facility if their
            medical condition poses direct health or safety to other people
            using the facility.
          </li>
        </ul>
        <br />
        <span className="hometext3">Waiver and Release</span>
        <ul>
          <li>
            Participants agree that by engaging in any physical activity, or by
            using any club facility on the premises, the participants do so at
            their own risk. This includes, without limitation, the use of the
            equipment, showers, parking area, or sidewalk and their
            participation in any activity, badminton socials, program or
            instruction now or in the near future made available.
          </li>
          <br />
          <li>
            Participants agree that they are voluntarily taking part in these
            activities and use of the facility and assume all risk of injury, or
            the contraction of any illness or medical condition that might
            result thereof of any damage, loss or theft of any personal
            property.
          </li>
        </ul>
      </div>
    </div>
  );
}
