import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Link } from "react-scroll";
import { ReactComponent as RACKET } from "../../assets/racket.svg";
import { ReactComponent as LEFTARROW } from "../../assets/leftarrow.svg";
import { Divider, ThemeProvider, createTheme } from "@mui/material";

const navItems = ["Home", "About Us", "Membership", "Pricing", "Contact Us"];

const style = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 780,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function Navebar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const closeDrawer = () => {
    setMobileOpen(false);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <List>
        {navItems.map((item, index) => (
          <div>
            <ListItem key={item} disablePadding>
              <Link
                to={item.replace(/\s+/g, "-").toLowerCase()}
                onClick={closeDrawer}
                smooth={true}
                spy={true}
                offset={-70}
              >
                <ListItemButton
                  sx={{ textAlign: "center" }}
                  onClick={handleDrawerToggle}
                >
                  <ListItemText primary={item} />
                </ListItemButton>
              </Link>
            </ListItem>
            <Divider sx={{ backgroundColor: "#ffffff80" }} />
          </div>
        ))}
      </List>
      <Button onClick={closeDrawer}>
        <LEFTARROW />
      </Button>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={style}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          sx={{
            background: "#00000080",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Link to="home" smooth={true} style={{ cursor: "pointer" }}>
              <RACKET style={{ width: 200, height: 85 }} />
            </Link>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item, index) => (
                <Link
                  key={item}
                  to={item.replace(/\s+/g, "-").toLowerCase()}
                  smooth={true}
                  spy={true}
                  offset={-70}
                >
                  <Button
                    key={index}
                    sx={{
                      color: "#ffffff",
                      textTransform: "none",
                      marginRight: "10px",
                      fontSize: "18px",
                      "&:hover": {
                        color: "#fade43",
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {item}
                  </Button>
                </Link>
              ))}
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              fontWeight: "bold",
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "100%",
                background: "#00000080",
                color: "#ffffff",
              },
              "& .MuiListItem-root": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
    </ThemeProvider>
  );
}

Navebar.propTypes = {
  window: PropTypes.func,
};

export default Navebar;
