//Auth APIs
export const LOGIN = "/api/auth/signin";
export const SIGNUP = "/api/auth/signUp";
export const ADMINSIGNUP = "/api/admin/users";
export const FORGOT_PASSWORD = "/api/forgot-password";
export const RESET_PASSWORD = "/api/reset-password";
export const CHANGE_PASSWORD = "/api/change-password";
export const VERIFY_ACCOUNT = "/api/verify";
export const RESEND_LINK = "/api/resent-verification";

//user APIs
export const USER_ME = "/api/users/context";
export const ADMINS = "/api/admin/users/admins";
export const GETUSER = "/api/admin/users/";
export const USER_INVOICE = "/api/invoice/user/me";

//courtBookings APIs
export const GET_COURTS = "/api/courts-management";
export const BOOKING_SLOTS = "/api/bookings/slots";
export const ADD_BOOKING = "/api/bookings/me";
export const UPDATE_TOKEN = "/api/bookings/me/token";
export const USER_BOKINGS = "/api/bookings/date-range";
export const DELETE_BOOKING = "/api/bookings/me/pending";
export const DELETE_ALL_PENDING_BOOKINGS = "/api/bookings/me";
export const CONFIRM_BOOKINGS = "/api/bookings/confirm";
export const CANCEL_BOOKINGS = "/api/bookings/cancel/bulk";
export const CANCEL_BOOKINGWITHID = "/api/bookings/cancel";
export const GET_BOOKINGWITHID = "/api/admin/bookings";

//promocode
export const CHECKOUT = "/api/bookings/checkout";
export const PROMOCODE = "/api/bookings/promotion-codes/apply/";
export const REVOKEPROMOCODE = "/api/bookings/promotion-codes/revoke/";

//admin APIs
export const ADMINLOGIN = "/api/admin/auth/signin";
export const COURT = "api/admin/courts";
export const COURTID = "/api/court/";
export const COURT_MANAGEMENT = "/api/courts-management";
export const ADMIN_COURT_MANAGEMENT = "/api/admin/courts-management";
export const ADD_PROMOCODE = "/api/admin/promotion-codes";
export const ADD_PROMOCODE_ID = "/api/admin/promotion-codes/";
export const GET_ADMIN_BOOKINGS = "/api/admin/bookings/slots";
export const ADMIN_CANCEL_BOOKING = "/api/admin/bookings/cancel";

//memberShip APIs
export const MEMBERSHIP = "/api/payments/membership";
export const MEMBERSHIP_CONFIG = "/api/admin/memberships";
export const MEMBERSHIP_CONFIG_ID = "/api/admin/memberships/";
export const GET_MEMBERSHIP = "/api/memberships";
export const GET_MEMBERSHIP_ID = "/api/memberships";

//payment APIs
export const PAYMENT_API = "/api/payment-integration/checkout";
export const PAYMENT_API_PROCESS = "/api/payments/process";

//Contact-us
export const CONTACTUS = "/api/email/contactUs";

// Logout
export const LOGOUT = "/api/auth/logout";
