import React, { useState } from "react";
import { Button, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { FORGOT_PASSWORD } from "../../constants/API";
import "../../style.css";
import { postRequest } from "../../utils/CommonAxios";
import { ReactComponent as KbtcIocn } from "../../assets/KbtcYellow.svg";
import { Box, Container } from "@mui/material";
import { USERNAME } from "../../constants/messages";
import validateField from "../../utils/ValidateField";

export default function ForgotPassword() {
  const [messageApi, contextHolder] = message.useMessage();
  const [email, setEmail] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [emailError, setEmailError] = useState("");

  const navigate = useNavigate();

  const validateForm = () => {
    let isValid = true;
    isValid = validateField(email, setEmailError, USERNAME);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const valid = validateForm();
    if (valid) {
      handleFogotPassword();
    }
  };

  const handleFogotPassword = async () => {
    const data = {
      email: email,
    };
    postRequest(FORGOT_PASSWORD, data)
      .then((response) => {
        setSuccessMsg(response.payload.data);
        setIsDisable(true);
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: error.error,
        });
      });
  };

  const redirectToSignup = () => {
    navigate("/signup");
  };

  return (
    <>
      <Container
        maxWidth="xs"
        sx={{
          maxWidth: "450px",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {contextHolder}
        <Box>
          <div className="white-box">
            <span className="loginLogo">
              <KbtcIocn />
            </span>
            <h3 className="h1h2Login">Forgot Password</h3>
            <center>
              <p className="loginText">
                Enter your login username and we will email you the password
                reset instructions.
              </p>
            </center>
            <form onSubmit={handleSubmit}>
              <Input
                className="inputField"
                placeholder="enter your username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => validateForm(e.target.value)}
              />
              <span className="errorMessage">{emailError}</span>
              <br />
              <br />
              <Button
                className="buttonLogin"
                htmlType="submit"
                disabled={isDisable}
                style={{ backgroundColor: isDisable ? "#fade43" : "#fade43" }}
              >
                {isDisable ? <>Submit</> : <>Submit</>}
              </Button>
            </form>
            <br />
            {successMsg && (
              <div className="alert alert-success alert-dismissable margin-top: 20px;">
                {successMsg}
              </div>
            )}
            <center>
              <p className="loginText">
                <a className="aLogin" href="login">
                  Login here
                </a>
              </p>
              <p className="loginText">
                Are you new?
                <a href="signup" className="aLogin" onClick={redirectToSignup}>
                  Signup here
                </a>
              </p>
            </center>
          </div>
        </Box>
      </Container>
    </>
  );
}
